import Vue from 'vue'
import {
  NotFoundError,
  NetworkError,
  StopUidError,
  StopSvsError,
  MaintenanceError,
  AuthError,
  RightsError,
  DeviceError,
  SessionTimeoutError,
  LoginOverError,
  SessionOverError,
  AccountLockError,
} from '@/plugins/exception'

/**
 * スリープ
 * @params {number} msec
 */
const sleep = (msec: number) => {
  return new Promise((resolve) => setTimeout(resolve, msec))
}

/**
 * アラート表示 or 例外を投げる
 * @params {any} e
 * @return {void}
 */
export function $$alertOrException(message: string): void {
  if (location.pathname.match(/^\/noteplus\//)) {
    throw new Error(message)
  }
  alert(message)
}

/**
 * エラーページにリダイレクトする
 * @params {any} e
 * @return {Promise<boolean>}
 */
export async function $$redirectErrorPage(e: any): Promise<void> {
  if (location.pathname.match(/^\/noteplus\//)) {
    throw e
  }

  let path = '/error.html'
  if (e instanceof NotFoundError) {
    path = '/notfound.html'
  } else if (e instanceof NetworkError) {
    path = '/networkerr.html'
  } else if (e instanceof StopUidError) {
    path = '/stopuid.html'
  } else if (e instanceof StopSvsError) {
    path = '/stopsvs.html'
  } else if (e instanceof MaintenanceError) {
    path = '/maintenance.html'
  } else if (e instanceof AuthError) {
    path = '/authfail.html'
  } else if (e instanceof RightsError) {
    path = '/norights.html'
  } else if (e instanceof DeviceError) {
    path = '/deviceerr.html'
  } else if (e instanceof SessionTimeoutError) {
    path = '/sessiontimeout.html'
  } else if (e instanceof LoginOverError) {
    path = '/loginover.html'
  } else if (e instanceof SessionOverError) {
    path = '/sessionover.html'
  } else if (e instanceof AccountLockError) {
    path = '/invaliderr.html'
  }
  console.error(e)
  console.log(path)

  // リダイレクト
  location.href = path

  // 3秒スリープ
  await sleep(3000)
}

export default (): void => {
  // Vue.js内で発生したエラー
  Vue.config.errorHandler = (e) => {
    console.error('vue errorHandler')
    $$redirectErrorPage(e)
  }

  // Vue.js外で発生したエラー
  window.addEventListener('error', (event) => {
    console.error('window addEventListener error')
    console.error(event)
    // location.href = '/error.html'
  })

  // Promise.rejectedエラー
  window.addEventListener('unhandledRejection', (event) => {
    console.error('window unhandledRejection error')
    console.error(event)
    location.href = '/error.html'
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $$alertOrException(message: string): void
    $$redirectErrorPage(e: any): void
  }
}

Vue.prototype.$$alertOrException = $$alertOrException
Vue.prototype.$$redirectErrorPage = $$redirectErrorPage
