import Vue from 'vue'
import { MyFolder, DangoRequest, DangoResponse, DangoForm } from '@/types/dango'
import { storeLoginInfo } from '@/plugins/user'
import { storeMyFolderInfo } from '@/plugins/myfolder'
import {
  login,
  auth,
  logout,
  search,
  getRelatedKeywords,
  getDetail,
  getThumbnail,
  getRealData,
  getRealDataBinary,
  changePassword,
  getmyfhznkey,
  updmyfInfos,
  myfuserelease,
  getmyfdata,
} from '@/plugins/dangoapi'
import {
  StopSvsError,
  MaintenanceError,
  AuthError,
  RightsError,
  SessionTimeoutError,
  LoginOverError,
  SessionOverError,
  AccountLockError,
  SystemError,
} from '@/plugins/exception'

/**
 * インターフェースを追加
 */
declare module 'vue/types/vue' {
  interface Vue {
    $$login(isMulti?: number): Promise<boolean>
    $$loginWithIp(): Promise<boolean>
    $$auth(): Promise<boolean>
    $$logout(): Promise<boolean>
    $$getInitializedSrchInfo(srchIds: string[]): DangoForm.srchInfoIF[]
    $$search(): Promise<void>
    $$reSearch(page: number): Promise<void>
    $$getRelatedKeywords(): Promise<void>
    $$getDetail(): Promise<void>
    $$getRealData(): Promise<void>
    $$getThumbnail(): Promise<void>
    $$changePassword(): Promise<boolean>
    $$makeMyFolderHznKey(): Promise<string>
    $$makeMyFolder(hznkey: string): Promise<boolean>
    $$restoreMyFolder(hznkey: string): Promise<boolean>
    $$copyMyFolder(): Promise<string>
    $$addMyFolderInfo(kinouId: string, hznJson: string): Promise<boolean>
    $$updateMyFolderInfo(
      kinouId: string,
      meisaiId: string,
      hznJson: string
    ): Promise<boolean>
    $$deleteMyFolderInfo(kinouId: string, meisaiId: string): Promise<boolean>
    $$releaseMyFolder(): Promise<boolean>
    $$getMyFolderJson(keys: (keyof MyFolder.detailIF)[]): Promise<boolean>
  }
}

/**
 * ページ認可のerrCodeをチェックする
 * @params {string} errCode
 * @return {Promise<void>}
 */
async function checkAuthErrorCode(errCode: string): Promise<void> {
  try {
    // セッションタイムアウトであればエラー
    if (errCode === 'MSTEC030006') {
      throw new SessionTimeoutError()
    }
    // システム or サービスがメンテナンス中であればエラー
    if (errCode === 'MSTEC030007' || errCode === 'MSTEC030011') {
      throw new MaintenanceError()
    }
    // 契約 or 検索サービス停止中であればエラー
    if (errCode === 'MSTEC030009' || errCode === 'MSTEC030010') {
      throw new StopSvsError()
    }
    // 最大ログイン数オーバーであればエラー
    if (errCode === 'MSTEC030013') {
      throw new LoginOverError()
    }
    // セッションオーバーであればエラー
    if (errCode === 'MSTEC030014' || errCode === 'MSTEC030041') {
      throw new SessionOverError()
    }
    // 権限対象外であればエラー
    if (errCode === 'MSTEC030018') {
      throw new RightsError()
    }
    // アカウントロックであればエラー
    if (errCode === 'MSTEC030046') {
      throw new AccountLockError()
    }
    // 認可エラーコードであればエラー
    if (errCode.match(/^MSTEC03\d+?/)) {
      throw new AuthError()
    }
  } catch (e) {
    await parent.$nuxt.$$redirectErrorPage(e)
  }
}

/**
 * errCodeをチェックする
 * @params {string} type
 * @params {any} res
 * @return {Promise<void>}
 */
async function checkDangoErrorCode(type: string, res: any): Promise<void> {
  console.log(type, res)
  try {
    if (type === 'login') {
      res = res as DangoResponse.loginIF
      if (res.status === false) {
        // システム or サービスがメンテナンス中であればエラー
        if (res.errCode === 'MSTEC010003' || res.errCode === 'MSTEC010010') {
          throw new MaintenanceError()
        }
        // 契約 or 検索サービス停止中であればエラー
        if (res.errCode === 'MSTEC010008' || res.errCode === 'MSTEC010009') {
          console.log('StopSvsError')
          throw new StopSvsError()
        }
        // 最大ログイン数オーバーであればエラー
        if (res.errCode === 'MSTEC010012') {
          throw new LoginOverError()
        }
        // セッションオーバーであればエラー
        if (res.errCode === 'MSTEC010013') {
          throw new SessionOverError()
        }
        // アカウントロックであればエラー
        if (res.errCode === 'MSTEC010030') {
          throw new AccountLockError()
        }
      }
    } else if (type === 'auth') {
      // ログイン画面で使用する（ログイン後はauth.tsで認可チェックしている）
      res = res as DangoResponse.authIF
      if (res.status === false) {
        // システム or サービスがメンテナンス中であればエラー
        if (res.errCode === 'MSTEC030007' || res.errCode === 'MSTEC030011') {
          throw new MaintenanceError()
        }
        // 契約 or 検索サービス停止中であればエラー
        if (res.errCode === 'MSTEC030009' || res.errCode === 'MSTEC030010') {
          throw new StopSvsError()
        }
        // 最大ログイン数オーバーであればエラー
        if (res.errCode === 'MSTEC030013') {
          throw new LoginOverError()
        }
        // セッションオーバーであればエラー
        if (res.errCode === 'MSTEC030014' || res.errCode === 'MSTEC030041') {
          throw new SessionOverError()
        }
      }
    } else if (type === 'changePassword') {
      res = res as DangoResponse.changePasswordIF
      if (res.status === false) {
        // 認可エラーコードをチェック
        await checkAuthErrorCode(res.errCode)

        // 認証エラーであればエラー
        if (
          res.errCode === 'MSTEC080001' ||
          res.errCode === 'MSTEC080002' ||
          res.errCode === 'MSTEC080009' ||
          res.errCode === 'MSTEC080010' ||
          res.errCode === 'MSTEC080012' ||
          res.errCode === 'MSTEC080013' ||
          res.errCode === 'MSTEC080014'
        ) {
          throw new AuthError()
        }
        // 内部エラーであればエラー
        if (
          res.errCode === 'MSTEC080003' ||
          res.errCode === 'MSTEC080004' ||
          res.errCode === 'MSTEC080005'
        ) {
          throw new SystemError()
        }
        // システムメンテナンス中であればエラー
        if (res.errCode === 'MSTEC080008') {
          throw new MaintenanceError()
        }
      }
    } else if (type === 'getMyFolderHznKey') {
      res = res as DangoResponse.getmyfhznkeyIF
      if (res.status === false) {
        // 認可エラーコードをチェック
        await checkAuthErrorCode(res.errCode)

        // 認証エラーであればエラー
        if (
          res.errCode === 'MSTEH010002' ||
          res.errCode === 'MSTEH010003' ||
          res.errCode === 'MSTEH010006' ||
          res.errCode === 'MSTEH010007' ||
          res.errCode === 'MSTEH010008' ||
          res.errCode === 'MSTEH010018' ||
          res.errCode === 'MSTEH010019'
        ) {
          throw new AuthError()
        }
        // 内部エラーであればエラー
        if (
          res.errCode === 'MSTEH010001' ||
          res.errCode === 'MSTEH010004' ||
          res.errCode === 'MSTEH010005' ||
          res.errCode === 'MSTEH010017'
        ) {
          throw new SystemError()
        }
        // システムメンテナンス中であればエラー
        if (res.errCode === 'MSTEH010010') {
          throw new MaintenanceError()
        }
      }
    } else if (type === 'updateMyFolderInfo') {
      res = res as DangoResponse.updmyfInfosIF
      if (res.status === false) {
        // 認可エラーコードをチェック
        await checkAuthErrorCode(res.errCode)

        // 認証エラーであればエラー
        if (
          res.errCode === 'MSTEH030002' ||
          res.errCode === 'MSTEH030003' ||
          res.errCode === 'MSTEH030007' ||
          res.errCode === 'MSTEH030008' ||
          res.errCode === 'MSTEH030009' ||
          res.errCode === 'MSTEH030010' ||
          res.errCode === 'MSTEH030012' ||
          res.errCode === 'MSTEH030013' ||
          res.errCode === 'MSTEH030014'
        ) {
          throw new AuthError()
        }
        // 内部エラーであればエラー
        if (
          res.errCode === 'MSTEH030001' ||
          res.errCode === 'MSTEH030004' ||
          res.errCode === 'MSTEH030005' ||
          res.errCode === 'MSTEH030006' ||
          res.errCode === 'MSTEH030016' ||
          res.errCode === 'MSTEH030017' ||
          res.errCode === 'MSTEH030018' ||
          res.errCode === 'MSTEH030019' ||
          res.errCode === 'MSTEH030021' ||
          res.errCode === 'MSTEH030022' ||
          res.errCode === 'MSTEH030023' ||
          res.errCode === 'MSTEH030024' ||
          res.errCode === 'MSTEH030025'
        ) {
          throw new SystemError()
        }
        // システムメンテナンス中であればエラー
        if (res.errCode === 'MSTEH030015') {
          throw new MaintenanceError()
        }
      }
    } else if (type === 'releaseMyFolder') {
      res = res as DangoResponse.myfusereleaseIF
      if (res.status === false) {
        // 認可エラーコードをチェック
        await checkAuthErrorCode(res.errCode)

        // 認証エラーであればエラー
        if (
          res.errCode === 'MSTEH040002' ||
          res.errCode === 'MSTEH040004' ||
          res.errCode === 'MSTEH040005' ||
          res.errCode === 'MSTEH040006' ||
          res.errCode === 'MSTEH040007' ||
          res.errCode === 'MSTEH040008' ||
          res.errCode === 'MSTEH040009' ||
          res.errCode === 'MSTEH040010' ||
          res.errCode === 'MSTEH040011' ||
          res.errCode === 'MSTEH040012' ||
          res.errCode === 'MSTEH040013'
        ) {
          throw new AuthError()
        }
        // 内部エラーであればエラー
        if (res.errCode === 'MSTEH040001' || res.errCode === 'MSTEH040003') {
          throw new SystemError()
        }
        // システムメンテナンス中であればエラー
        if (res.errCode === 'MSTEH040014') {
          throw new MaintenanceError()
        }
      }
    }
  } catch (e) {
    await parent.$nuxt.$$redirectErrorPage(e)
  }
}

/**
 * ログインする
 * @params number isMulti
 * @return {Promise<boolean>}
 */
Vue.prototype.$$login = async function (isMulti: number = 0): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query: DangoRequest.loginIF = {
    domainNm: document.domain,
    loginId: this.loginId,
    passWd: this.passWd,
  }

  // 端末認証フラグ
  if (isMulti === 1) {
    query.termAuthFlg = 1
  }

  // 親子孫ID対応
  if (this.subLoginId !== '') {
    query.loginId = this.subLoginId
    query.loginOyaId = this.loginId
  }

  // APIをコールする
  const res: DangoResponse.loginIF = await login(query)

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('login', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)

    // ローディング画面を隠す
    this.$$hideLoading()
  }

  return res.status
}

/**
 * IP認証でログインする
 * @return {Promise<boolean>}
 */
Vue.prototype.$$loginWithIp = async function (): Promise<boolean> {
  // クエリを初期化
  const query: DangoRequest.loginIF = {
    domainNm: document.domain,
  }

  // APIをコールする
  const res: DangoResponse.loginIF = await login(query)

  // エラーコードをチェックする
  await checkDangoErrorCode('login', res)

  return res.status
}

/**
 * 認可（/login/index.vue専用）
 * @return {Promise<boolean>}
 */
Vue.prototype.$$auth = async function (): Promise<boolean> {
  // クエリを初期化
  const query: DangoRequest.authIF = {
    domainNm: document.domain,
    saveloginFlg: '1',
  }

  // APIをコールする
  const res: DangoResponse.authIF = await auth(query)

  // エラーコードをチェックする
  await checkDangoErrorCode('auth', res)

  return res.status
}

/**
 * ログアウトする
 * @return {Promise<boolean>}
 */
Vue.prototype.$$logout = async function (): Promise<boolean> {
  // クエリを初期化
  const query: DangoRequest.logoutIF = {
    domainNm: document.domain,
  }

  // APIをコールする
  const res: DangoResponse.logoutIF = await logout(query)

  return res.status
}

/**
 * mstStatusをチェックする
 * @params {string} type
 * @params {any} res
 * @params {number} alertFlg
 * @return {Promise<boolean>}
 */
async function checkMstStatus(
  type: string,
  res: any,
  alertFlg: number
): Promise<boolean> {
  if (type === 'search') {
    res = res as DangoResponse.searchIF
  } else if (type === 'relatedKeywords') {
    res = res as DangoResponse.relatedKeywordsIF
  } else if (type === 'detail') {
    res = res as DangoResponse.detailIF
  } else if (type === 'realdata') {
    res = res as DangoResponse.realDataIF
  } else if (type === 'thumbnail') {
    res = res as DangoResponse.thumbnailIF
  } else {
    return false
  }

  const mstStatus = parent.$nuxt.$$const.API_RESPONSE.MST_STATUS
  const srchStatus = parent.$nuxt.$$const.API_RESPONSE.SRCH_STATUS

  // mstStatusがない
  if (res.mstStatus === undefined) {
    if (alertFlg) {
      parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
    } else {
      console.log(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
    }
    return false
  }

  // 認証エラー
  if (res.mstStatus === mstStatus.AUTH_FAILED) {
    // 認可エラーコードをチェック
    await checkAuthErrorCode(res.mstErrCode)
  }

  // エラーメッセージ
  let errMsg = ''

  // 編集処理失敗
  if (res.mstStatus === mstStatus.EDIT_FAILED) {
    errMsg = res.mstErrMsg
  }

  // パラメータ不備
  if (res.mstStatus === mstStatus.INVALID_PARAM) {
    if (type === 'search' && res.mstErrCode === 'MSTEK010125') {
      if (res.result) {
        for (const row of res.result) {
          if (
            row.srchStatus !== undefined &&
            row.srchStatus !== srchStatus.SUCCESS
          ) {
            errMsg += row.srchErrMsg + '\n'
          }
        }
      }
    }
    if (errMsg === '') {
      errMsg = res.mstErrMsg
    }
  }

  // 異常終了
  if (res.mstStatus === mstStatus.FAILED) {
    if (type === 'search' && res.mstErrCode === 'MSTEK010126') {
      if (res.result) {
        for (const row of res.result) {
          if (
            row.srchStatus !== undefined &&
            row.srchStatus !== srchStatus.SUCCESS
          ) {
            errMsg += row.srchErrMsg + '\n'
          }
        }
      }
    }
    if (errMsg === '') {
      errMsg = res.mstErrMsg
    }
  }

  if (errMsg !== '') {
    errMsg = errMsg.replace('[&]', '[ AND ]').replace('[#]', '[ NOT ]')
    if (alertFlg) {
      parent.$nuxt.$$alertOrException(errMsg)
    } else {
      console.log(errMsg)
    }
    return false
  }

  return true
}

/**
 * 初期化された検索条件を取得する
 * @params {string[]} srchIds
 * @return {DangoForm.srchInfoIF[]}
 */
Vue.prototype.$$getInitializedSrchInfo = function (
  srchIds: string[]
): DangoForm.srchInfoIF[] {
  // 検索情報
  const srchInfo: DangoForm.srchInfoIF[] = []

  // 検索対象インデックス番号
  let srchNo: number = 0

  for (const srchId of srchIds) {
    if (srchId === this.$$const.API.srchId.KIJI) {
      // 検索対象の紙誌名かチェック
      const shishiNames = []
      if (this.$$getLoginInfo('SV_DATA_ASAHI') === 'Y') {
        shishiNames.push(this.$$const.API.shishiName.ASAHI_SHIMBUN)
        shishiNames.push(this.$$const.API.shishiName.ASAHI_SHIMBUN_DIGITAL)
      }
      if (this.$$getLoginInfo('SV_DATA_AERA') === 'Y') {
        shishiNames.push(this.$$const.API.shishiName.AERA)
      }
      if (this.$$getLoginInfo('SV_DATA_WEEKLY') === 'Y') {
        shishiNames.push(this.$$const.API.shishiName.SHUKAN_ASAHI)
      }
      srchInfo.push({
        srchNo,
        srchId,
        keyword: '',
        itaijiFlg: this.$$const.API.itaijiFlg.TRUE,
        dougigoFlg: this.$$const.API.dougigoFlg.TRUE,
        shishiName: '',
        shishiNames,
        srchTerm: this.$$const.API.srchTerm.ALL,
        hakkouDateF: '',
        hakkouDateT: '',
        hakkouDateF_y: '',
        hakkouDateF_m: '',
        hakkouDateF_d: '',
        hakkouDateConjunction: this.$$const.FORM.hakkouDateConjunction.FROM,
        hakkouDateT_y: '',
        hakkouDateT_m: '',
        hakkouDateT_d: '',
        keywordTarget: this.$$const.API.keywordTarget.MIDASHI_HONBUN_KEYWORD,
        srchMode: this.$$const.API.srchMode.WORD_MATCH_SEARCH,
        bunrui1: '',
        bunrui2: '',
        kanshuName: '',
        kanshuNames: [],
        menName: '',
        menshuAName: '',
        menshuANames: [],
        hakkoushaName: '',
        hakkoushaNames: [],
        kirinukiUseReq: this.$$const.API.kirinukiUseReq.FALSE,
        dspNum: 20,
        dspOrder: this.$$const.API.dspOrder.NEWEST_TO_OLDEST,
        dspType: this.$$const.API.dspType.START,
      })
    } else if (
      srchId === this.$$const.API.srchId.SHIMEN &&
      (this.$route.path === '/top/' ||
        this.$route.path === '/shimen/' ||
        this.$route.path === '/shimen/date/')
    ) {
      srchInfo.push({
        srchNo,
        srchId,
        srchOpt: 0,
        keyword: '',
        kijiTFlg: this.$$const.API.kijiTFlg.TRUE,
        kijiCFlg: this.$$const.API.kijiCFlg.TRUE,
        itaijiFlg: this.$$const.API.itaijiFlg.TRUE,
        dougigoFlg: this.$$const.API.dougigoFlg.TRUE,
        srchAge: '',
        srchAges: this.$$getSrchAgesAuth(),
        hakkouDateF: '',
        hakkouDateT: '',
        hakkouDateF_y: '',
        hakkouDateF_m: '',
        hakkouDateF_d: '',
        hakkouDateConjunction: this.$$const.FORM.hakkouDateConjunction.FROM,
        hakkouDateT_y: '',
        hakkouDateT_m: '',
        hakkouDateT_d: '',
        hakkoushaName: '',
        hakkoushaNames: [
          this.$$const.API.hakkoushaName.TOKYO,
          this.$$const.API.hakkoushaName.OSAKA,
          this.$$const.API.hakkoushaName.NAGOYA,
          this.$$const.API.hakkoushaName.SEIBU,
          this.$$const.API.hakkoushaName.HOKKAIDO,
        ],
        kanshuName: '',
        kanshuNames: [
          this.$$const.API.kanshuName.MORNING,
          this.$$const.API.kanshuName.EVENING,
          this.$$const.API.kanshuName.GOUGAI,
          this.$$const.API.kanshuName.FUROKU,
          this.$$const.API.kanshuName.EXTRA,
        ],
        menshuAName: this.$$const.API.menshuAName.HONSHI,
        keisaiPage: undefined,
        keywordTarget: this.$$const.API.keywordTarget.MIDASHI_KEYWORD,
        srchMode: this.$$const.API.srchMode.WORD_MATCH_SEARCH,
        bunrui1: '',
        bunrui2: '',
        bunruiConn: this.$$const.API.bunruiConn.AND,
        dspNaiyo: this.$$const.API.dspNaiyo.MIDASHI_KEYWORD,
        dspNum: 50,
        dspOrder: this.$$const.API.dspOrder.OLDEST_TO_NEWEST,
        dspType: this.$$const.API.dspType.START,
      })
    } else if (
      srchId === this.$$const.API.srchId.SHIMEN &&
      this.$route.path === '/shimen/region/'
    ) {
      srchInfo.push({
        srchNo,
        srchId,
        hakkouDateF: '',
        hakkouDateT: '',
        hakkouDateF_y: '',
        hakkouDateF_m: '',
        hakkouDateF_d: '',
        hakkouDateConjunction: this.$$const.FORM.hakkouDateConjunction.FROM,
        hakkouDateT_y: '',
        hakkouDateT_m: '',
        hakkouDateT_d: '',
        menshuAName: this.$$const.API.menshuAName.REGION,
        countryArea: '',
        countryAreas: this.setCountryArea(),
        srchMode: this.$$const.API.srchMode.WORD_MATCH_SEARCH,
        dspNum: 50,
        dspOrder: this.$$const.API.dspOrder.OLDEST_TO_NEWEST,
        dspType: this.$$const.API.dspType.START,
      })
    } else if (
      srchId === this.$$const.API.srchId.SHIMEN &&
      this.$route.path === '/shimen/gaichi/'
    ) {
      srchInfo.push({
        srchNo,
        srchId,
        hakkouDateF: '',
        hakkouDateT: '',
        hakkouDateF_y: '',
        hakkouDateF_m: '',
        hakkouDateF_d: '',
        hakkouDateConjunction: this.$$const.FORM.hakkouDateConjunction.FROM,
        hakkouDateT_y: '',
        hakkouDateT_m: '',
        hakkouDateT_d: '',
        menshuAName: this.$$const.API.menshuAName.GAICHI,
        countryArea: '',
        countryAreas: this.setCountryArea(),
        srchMode: this.$$const.API.srchMode.WORD_MATCH_SEARCH,
        dspNum: 50,
        dspOrder: this.$$const.API.dspOrder.OLDEST_TO_NEWEST,
        dspType: this.$$const.API.dspType.START,
      })
    } else if (srchId === this.$$const.API.srchId.CHIEZO) {
      srchInfo.push({
        srchNo,
        srchId,
        keyword: '',
        wordType: '',
        wordTypes: [
          this.$$const.API.wordType.IPPANYOGO,
          this.$$const.API.wordType.RYAKUGO_GAIRAIGO,
        ],
        keywordTarget: this.$$const.API.keywordTarget.MIDASHI_HONBUN,
        srchMode: this.$$const.API.srchMode.WORD_MATCH_SEARCH,
        bunrui1: '',
        dspNum: 20,
        dspOrder: this.$$const.API.dspOrder.OLDEST_TO_NEWEST,
        dspType: this.$$const.API.dspType.START,
      })
    } else if (srchId === this.$$const.API.srchId.JIMBUTSU) {
      srchInfo.push({
        srchNo,
        srchId,
        keyword: '',
        itaijiFlg: this.$$const.API.itaijiFlg.TRUE,
        kojinFlg: this.$$const.API.kojinFlg.FALSE,
        katagaki: '',
        bunrui1: '',
        jyusyo: '',
        keireki: '',
        birthday: '',
        crossSearch: '',
        dspNum: 20,
        dspOrder: this.$$const.API.dspOrder.JAPANESE_SYLLABARY,
        dspType: this.$$const.API.dspType.START,
      })
    } else if (srchId === this.$$const.API.srchId.GRAPH) {
      srchInfo.push({
        srchNo,
        srchId,
        keyword: '',
        hakkouDateF: '',
        hakkouDateT: '',
        hakkouDateF_y: '',
        hakkouDateF_m: '',
        hakkouDateF_d: '',
        hakkouDateConjunction: this.$$const.FORM.hakkouDateConjunction.FROM,
        hakkouDateT_y: '',
        hakkouDateT_m: '',
        hakkouDateT_d: '',
        srchMode: this.$$const.API.srchMode.WORD_MATCH_SEARCH,
        dspNum: 50,
        dspOrder: this.$$const.API.dspOrder.OLDEST_TO_NEWEST,
        dspType: this.$$const.API.dspType.START,
      })
    } else if (srchId === this.$$const.API.srchId.ENGLISH) {
      srchInfo.push({
        srchNo,
        srchId,
        keyword: '',
        bunrui1: '',
        bunrui2: '',
        hakkouDateF: '',
        hakkouDateT: '',
        keywordTarget: '',
        keywordTargets: [
          this.$$const.API.keywordTarget.MIDASHI,
          this.$$const.API.keywordTarget.HONBUN,
          this.$$const.API.keywordTarget.KEYWORD,
          this.$$const.API.keywordTarget.BYLINE,
        ],
        countryArea: '',
        dspNum: 20,
        dspOrder: this.$$const.API.dspOrder.NEWEST_TO_OLDEST,
        dspType: this.$$const.API.dspType.START,
      })
    }
    ++srchNo
  }
  return srchInfo
}

/**
 * リスト検索する
 * @return {Promise<void>}
 */
Vue.prototype.$$search = async function (): Promise<void> {
  // ローディング画面を表示する
  this.$$showLoading()

  // 横断検索判定
  let srchUnit = this.$$const.API.srchUnit.SINGLE
  if (this.srchInfo.length > 1) {
    srchUnit = this.$$const.API.srchUnit.CROSS
  }

  // クエリ初期化
  const query: DangoRequest.searchIF = {
    domainNm: document.domain,
    srchUnit,
    accessChkFlg: this.$$const.API.accessChkFlg.TRUE,
  }

  // 検索条件初期化
  const condition = []

  // 検索情報
  const srchInfo: DangoRequest.srchInfoIF[] = []

  // ハイライト用キーワード
  let keywords = null

  for (const si of this.srchInfo) {
    // オブジェクトの値をコピー
    const s = Object.assign({}, si)

    // 配列（チェックボックス）を文字列に変換する
    for (const key of [
      'shishiName',
      'srchAge',
      'keywordTarget',
      'wordType',
      'kanshuName',
      'menshuAName',
      'hakkoushaName',
      'countryArea',
    ]) {
      if (s[key + 's'] !== undefined) {
        if (s[key] !== undefined) {
          s[key] = s[key + 's'].join(',')

          // 都道府県・地域が空の場合、全選択
          if (key === 'countryArea' && s[key] === '') {
            s[key] = this.setCountryArea().join(',')
          }

          si[key] = s[key]
        }
        delete s[key + 's']
      }
    }

    // 日付
    for (const key of ['hakkouDateF', 'hakkouDateT']) {
      delete s[key + '_y']
      delete s[key + '_m']
      delete s[key + '_d']
    }
    if (s.hakkouDateConjunction !== undefined) {
      delete s.hakkouDateConjunction
    }

    // 分類、面名
    for (const key of ['bunrui1', 'bunrui2', 'menName']) {
      if (s[key] !== undefined) {
        s[key] = s[key].$trim().$setOperator(0)
      }
    }

    // キーワード
    if (s.keyword !== undefined) {
      let k = s.keyword

      // トリミングする
      s.keyword = s.keyword.$trim()

      // 演算子をセットする
      let quoteMode = 0 // ダブルクォーテーション対応モード
      if (s.srchId === this.$$const.API.srchId.ENGLISH) {
        quoteMode = 1
      }
      s.keyword = s.keyword.$setOperator(quoteMode)

      // 文字を変換する
      if (
        s.srchId === this.$$const.API.srchId.KIJI ||
        s.srchId === this.$$const.API.srchId.SHIMEN ||
        s.srchId === this.$$const.API.srchId.OCR
      ) {
        if (s.itaijiFlg === this.$$const.API.itaijiFlg.TRUE) {
          s.keyword = s.keyword.$convertMidPoint(quoteMode)
        }
        k = s.keyword.$convertString(quoteMode, s.itaijiFlg, s.dougigoFlg)
      } else if (s.srchId === this.$$const.API.srchId.JIMBUTSU) {
        s.keyword = s.keyword.$convertString(quoteMode, s.itaijiFlg, 0)
        k = s.keyword
      }

      // ハイライト用に異体字、同義語展開したキーワード
      keywords = k.$explodeByOperator(0, 1, 1)
    }

    // 肩書き、住所、経歴・業績、項目横断
    for (const key of ['katagaki', 'jyusyo', 'keireki', 'crossSearch']) {
      if (
        s[key] !== undefined &&
        s.srchId === this.$$const.API.srchId.JIMBUTSU
      ) {
        s[key] = s[key].$trim().$setOperator(0)
        if (key === 'crossSearch') {
          s[key] = s[key].$convertString(0, s.itaijiFlg, 0)
        }
      }
    }
    srchInfo.push(s)
  }
  query.srchInfo = srchInfo

  // 単独検索の場合は検索条件を保存する
  if (srchUnit === this.$$const.API.srchUnit.SINGLE) {
    // リスト検索条件を保存
    for (const el of this.$el.querySelectorAll('[condition]')) {
      const key = el.id
      let val = this.srchInfo[0][key]
      const label = el.getAttribute('data-label')
      const labels: string[] = []
      if (
        key === 'itaijiFlg' ||
        key === 'dougigoFlg' ||
        key === 'kijiTFlg' ||
        key === 'kijiCFlg' ||
        key === 'kojinFlg' ||
        key === 'kirinukiUseReq'
      ) {
        if (val === this.$$const.API[key].FALSE) {
          continue
        }
        val = ''
      } else if (
        key === 'srchTerm' ||
        (key === 'keywordTarget' &&
          this.srchInfo[0].srchId !== this.$$const.API.srchId.ENGLISH) ||
        key === 'srchMode' ||
        key === 'dspNaiyo' ||
        key === 'bunruiConn'
      ) {
        Object.keys(this.$$const.API[key]).forEach((key2) => {
          if (val === this.$$const.API[key][key2]) {
            labels.push(key + 'ParamLabel.' + key2)
          }
        })
      } else if (
        key === 'srchAge' ||
        key === 'shishiName' ||
        key === 'keywordTarget' ||
        key === 'wordType' ||
        key === 'kanshuName' ||
        key === 'menshuAName' ||
        key === 'hakkoushaName'
      ) {
        if (this.srchInfo[0][key + 's'].length === 0) {
          continue
        }
        Object.keys(this.$$const.API[key]).forEach((key2) => {
          if (
            this.srchInfo[0][key + 's'].find(
              (el: string) => el === this.$$const.API[key][key2]
            )
          ) {
            labels.push(key + 'ParamLabel.' + key2)
          }
        })
      } else if (
        this.srchInfo[0].srchId !== this.$$const.API.srchId.ENGLISH &&
        key === 'countryArea'
      ) {
        if (this.srchInfo[0][key + 's'].length === 0) {
          continue
        }
        for (const code of this.srchInfo[0][key + 's']) {
          const target = document.getElementById(key + 's' + code)
          if (!target) {
            continue
          }
          const value = target.getAttribute('data-key')
          if (!value) {
            continue
          }
          labels.push(value)
        }
      } else if (val === '' || val === undefined) {
        continue
      }
      if (label !== null && label !== '') {
        condition.push([label, val, labels])
      } else {
        condition.push([key, val, labels])
      }
    }
  }

  // APIをコールする
  const res: DangoResponse.searchIF = await search(query)

  // 単独検索の後処理
  if (srchUnit === this.$$const.API.srchUnit.SINGLE) {
    // mstStatusの値をチェックする
    if (await checkMstStatus('search', res, 1)) {
      // 検索結果が無ければアラート表示 or 例外を投げる
      if (
        !res.result ||
        !res.result[0] ||
        res.result[0].totalCount === undefined
      ) {
        parent.$nuxt.$$alertOrException(
          parent.$nuxt.$$const.API_ERROR_MSG.COMMON
        )
      } else {
        // データをセット
        this.searchApi = {
          query,
          condition,
          result: res.result as DangoResponse.searchResultIF[],
        }
        if (Array.isArray(keywords) && this.searchApi !== undefined) {
          this.searchApi.keywords = keywords
        }
        const q = this.searchApi.query.srchInfo[0]
        const r = this.searchApi.result[0]
        this.searchApi.totalCount = r.totalCount
        this.searchApi.page = 1
        const dspNum = q.dspNum
        this.searchApi.start = (this.searchApi.page - 1) * dspNum + 1
        this.searchApi.end = this.searchApi.start + dspNum - 1
        if (this.searchApi.totalCount < this.searchApi.end) {
          this.searchApi.end = this.searchApi.totalCount
        }
        if (
          q.srchId === this.$$const.API.srchId.KIJI ||
          q.srchId === this.$$const.API.srchId.CHIEZO ||
          q.srchId === this.$$const.API.srchId.ENGLISH ||
          q.srchId === this.$$const.API.srchId.JIMBUTSU
        ) {
          // 記事IDのチェックボックスを全てクリアする
          this.$$clearAllKijiIdBox()
        } else if (q.srchId === this.$$const.API.srchId.SHIMEN) {
          this.searchApi.listSupScore = [0, this.searchApi.end]
        }
      }
    }

    // 単独検索以外の後処理
  } else {
    let errMsg = ''
    // mstStatusの値をチェックする
    if (await !checkMstStatus('search', res, 0)) {
      // パラメータ不備エラーは全ての検索が中断されるのでアラート表示 or 例外を投げる
      if (
        res.mstStatus === this.$$const.API_RESPONSE.MST_STATUS.INVALID_PARAM &&
        res.mstErrCode === 'MSTEK010125'
      ) {
        if (res.result) {
          for (const row of res.result) {
            if (
              row.srchStatus !== undefined &&
              row.srchStatus !== this.$$const.API_RESPONSE.SRCH_STATUS.SUCCESS
            ) {
              errMsg += row.srchErrMsg + '\n'
            }
          }
        }
      }
    }

    // 検索結果が無ければ共通エラー
    if (errMsg !== '' && !res.result) {
      errMsg = parent.$nuxt.$$const.API_ERROR_MSG.COMMON
    }

    if (errMsg !== '') {
      parent.$nuxt.$$alertOrException(errMsg)
    } else {
      // データをセット
      this.searchApi = {
        query,
        result: res.result as DangoResponse.searchResultIF[],
      }
      if (Array.isArray(keywords) && this.searchApi !== undefined) {
        this.searchApi.keywords = keywords
      }
    }
  }

  // ローディング画面を隠す
  this.$$hideLoading()

  // 警告メッセージがあれば表示する
  if (res.mstWarnMsg) {
    parent.$nuxt.$$alertOrException(res.mstWarnMsg)
  }
}

/**
 * リスト再検索する（単独検索用）
 * @params {number} page
 * @return {Promise<void>}
 */
Vue.prototype.$$reSearch = async function (page: number): Promise<void> {
  // ローディング画面を表示する
  this.$$showLoading()

  const q = this.searchApi.query.srchInfo[0]
  let r = this.searchApi.result[0]

  // 検索クエリの初期化
  delete q.hdnSupScore
  delete q.hdnInfScore
  delete q.hdnStartNo

  if (page === 1) {
    q.dspType = this.$$const.API.dspType.RESTART // 再検索

    // 紙面の継続用パラメーター配列を初期化
    if (q.srchId === this.$$const.API.srchId.SHIMEN) {
      this.searchApi.listSupScore = []
    }
  } else if (this.searchApi.page > page) {
    q.dspType = this.$$const.API.dspType.PREV // 前頁

    // DB毎にパラメータを設定
    if (
      q.srchId === this.$$const.API.srchId.KIJI ||
      q.srchId === this.$$const.API.srchId.CHIEZO
    ) {
      q.hdnInfScore = r.resultInfo.infScore
    } else if (q.srchId === this.$$const.API.srchId.SHIMEN) {
      q.hdnSupScore = this.searchApi.listSupScore[page - 1]
    } else if (q.srchId === this.$$const.API.srchId.ENGLISH) {
      q.hdnStartNo = (page - 1) * q.dspNum + 1
    } else if (
      q.srchId === this.$$const.API.srchId.JIMBUTSU ||
      q.srchId === this.$$const.API.srchId.GRAPH
    ) {
      q.hdnStartNo = (page - 1) * q.dspNum
    }
  } else if (this.searchApi.page < page) {
    q.dspType = this.$$const.API.dspType.NEXT // 次頁

    // DB毎にパラメータを設定
    if (
      q.srchId === this.$$const.API.srchId.KIJI ||
      q.srchId === this.$$const.API.srchId.CHIEZO
    ) {
      q.hdnSupScore = r.resultInfo.supScore
    } else if (q.srchId === this.$$const.API.srchId.SHIMEN) {
      q.hdnSupScore = this.searchApi.listSupScore[page - 1]
    } else if (q.srchId === this.$$const.API.srchId.ENGLISH) {
      q.hdnStartNo = (page - 1) * q.dspNum + 1
    } else if (
      q.srchId === this.$$const.API.srchId.JIMBUTSU ||
      q.srchId === this.$$const.API.srchId.GRAPH
    ) {
      q.hdnStartNo = (page - 1) * q.dspNum
    }
  }

  // APIをコールする
  const res: DangoResponse.searchIF = await search(this.searchApi.query)

  // mstStatusの値をチェックする
  if (await checkMstStatus('search', res, 1)) {
    if (
      !res.result ||
      !res.result[0] ||
      res.result[0].totalCount === undefined
    ) {
      parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
    } else {
      // 検結果をセット
      this.searchApi.result = res.result
      r = this.searchApi.result[0]
      this.searchApi.totalCount = r.totalCount
      this.searchApi.page = page
      const dspNum = q.dspNum
      this.searchApi.start = (this.searchApi.page - 1) * dspNum + 1
      this.searchApi.end = this.searchApi.start + dspNum - 1
      if (this.searchApi.totalCount < this.searchApi.end) {
        this.searchApi.end = this.searchApi.totalCount
      }
      if (
        q.srchId === this.$$const.API.srchId.KIJI ||
        q.srchId === this.$$const.API.srchId.CHIEZO ||
        q.srchId === this.$$const.API.srchId.ENGLISH ||
        q.srchId === this.$$const.API.srchId.JIMBUTSU
      ) {
        // 記事IDのチェックボックスを全てクリアする
        this.$$clearAllKijiIdBox()
      } else if (q.srchId === this.$$const.API.srchId.SHIMEN) {
        this.searchApi.listSupScore[page] = this.searchApi.end
        console.log(['listSupScore', this.searchApi.listSupScore])
      }
    }
  }

  // ローディング画面を隠す
  this.$$hideLoading()

  // 警告メッセージがあれば表示する
  if (res.mstWarnMsg) {
    parent.$nuxt.$$alertOrException(res.mstWarnMsg)
  }
}

/**
 * 関連キーワードを取得する
 * @return {Promise<void>}
 */
Vue.prototype.$$getRelatedKeywords = async function (): Promise<void> {
  // クエリを初期化
  const query: DangoRequest.relatedKeywordsIF = {
    domainNm: document.domain,
    keyword: this.srchInfo[0].keyword
      .$trim()
      .$setOperator(0)
      .$convertString(0, 0, 0, 0),
    accessChkFlg: this.$$const.API.accessChkFlg.TRUE,
  }

  // データを初期化
  this.relatedKeywordsApi = {
    query,
    result: [],
  }

  // APIをコールする
  const res: DangoResponse.relatedKeywordsIF = await getRelatedKeywords(query)

  // mstStatusの値をチェックする
  if (await checkMstStatus('relatedKeywords', res, 0)) {
    if (!res.result) {
      return
    }
    this.relatedKeywordsApi.result = res.result
  }

  // 警告メッセージがあれば表示する
  if (res.mstWarnMsg) {
    parent.$nuxt.$$alertOrException(res.mstWarnMsg)
  }
}

/**
 * 詳細情報を取得する
 * @return {Promise<void>}
 */
Vue.prototype.$$getDetail = async function (): Promise<void> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query = {
    domainNm: document.domain,
    srchId: this.detailApi.srchId,
    accessChkFlg: this.$$const.API.accessChkFlg.TRUE,
  } as DangoRequest.detailIF

  // DB毎にパラメータを設定
  if (query.srchId === this.$$const.API.srchId.KIJI) {
    const itemIds: string[] = []
    for (const row of this.detailApi.itemInfo) {
      itemIds.push(row[0])
    }
    query.itemId = itemIds.join(',')
    query.thumbnailFlg = this.$$const.API.thumbnailFlg.TRUE
    if (this.detailApi.thumbnailFlg !== undefined) {
      query.thumbnailFlg = this.detailApi.thumbnailFlg
    }
    query.dspOrder = this.detailApi.dspOrder
    if (this.detailApi.srchOpt !== undefined) {
      query.srchOpt = this.detailApi.srchOpt
    }
  } else if (
    query.srchId === this.$$const.API.srchId.SHIMEN &&
    this.detailApi.srchMenshu === this.$$const.API.menshuAName.HONSHI &&
    (this.detailApi.srchOpt === this.$$const.API.srchOpt.HONSHI_KW ||
      this.detailApi.srchOpt === this.$$const.API.srchOpt.HONSHI_KW_NOHISTORY)
  ) {
    query.itemId = this.detailApi.itemId
    query.thumbnailFlg = this.$$const.API.thumbnailFlg.FALSE
    query.srchKeyword = this.detailApi.srchKeyword
    query.issueDate = this.detailApi.issueDate
    query.srchMenshu = this.detailApi.srchMenshu
    query.srchOpt = this.detailApi.srchOpt
    query.hakkoushaName = this.detailApi.hakkoushaName
    query.getDetail2102_flg = this.detailApi.getDetail2102_flg
  } else if (
    query.srchId === this.$$const.API.srchId.SHIMEN &&
    this.detailApi.srchMenshu === this.$$const.API.menshuAName.HONSHI &&
    this.detailApi.srchOpt === this.$$const.API.srchOpt.HONSHI_DATE
  ) {
    query.thumbnailFlg = this.$$const.API.thumbnailFlg.FALSE
    query.issueDate = this.detailApi.issueDate
    query.srchMenshu = this.detailApi.srchMenshu
    query.srchType = this.detailApi.srchType
    query.srchOpt = this.detailApi.srchOpt
    query.hakkoushaName = this.detailApi.hakkoushaName
    query.getDetail2102_flg = this.detailApi.getDetail2102_flg
  } else if (
    query.srchId === this.$$const.API.srchId.SHIMEN &&
    (this.detailApi.srchMenshu === this.$$const.API.menshuAName.REGION ||
      this.detailApi.srchMenshu === this.$$const.API.menshuAName.GAICHI)
  ) {
    query.thumbnailFlg = this.$$const.API.thumbnailFlg.FALSE
    query.issueDate = this.detailApi.issueDate
    query.srchMenshu = this.detailApi.srchMenshu
    query.countryArea = this.detailApi.countryArea
    query.getDetail2102_flg = this.detailApi.getDetail2102_flg
  } else if (query.srchId === this.$$const.API.srchId.GRAPH) {
    query.thumbnailFlg = this.$$const.API.thumbnailFlg.FALSE
    query.itemId = this.detailApi.itemId
  } else if (
    query.srchId === this.$$const.API.srchId.CHIEZO ||
    query.srchId === this.$$const.API.srchId.JIMBUTSU
  ) {
    const itemIds: string[] = []
    for (const row of this.detailApi.itemInfo) {
      itemIds.push(row[0])
    }
    query.itemId = itemIds.join(',')
    query.thumbnailFlg = this.$$const.API.thumbnailFlg.FALSE
    query.dspOrder = this.detailApi.dspOrder
  } else if (query.srchId === this.$$const.API.srchId.ENGLISH) {
    const itemIds: string[] = []
    for (const row of this.detailApi.itemInfo) {
      itemIds.push(row[0])
    }
    query.itemId = itemIds.join(',')
    query.thumbnailFlg = this.$$const.API.thumbnailFlg.TRUE
    query.srchKeyword = this.detailApi.srchKeyword
    query.dspOrder = this.detailApi.dspOrder
  }

  // APIをコールする
  const res: DangoResponse.detailIF = await getDetail(query)
  // mstStatusの値をチェックする
  if (await checkMstStatus('detail', res, 1)) {
    if (!res.result) {
      parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
    } else {
      this.detailApi.result = res.result
    }
  }

  // ローディング画面を隠す
  this.$$hideLoading()

  // 警告メッセージがあれば表示する
  if (res.mstWarnMsg) {
    parent.$nuxt.$$alertOrException(res.mstWarnMsg)
  }
}

/**
 * サムネイル情報を取得する
 * @return {Promise<void>}
 */
Vue.prototype.$$getThumbnail = async function (): Promise<void> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query = {
    domainNm: document.domain,
    srchKind: this.thumbnailApi.srchKind,
    accessChkFlg: this.$$const.API.accessChkFlg.TRUE,
  } as DangoRequest.thumbnailIF

  // DB毎にパラメータを設定
  if (
    query.srchKind === this.$$const.API.srchId.SHIMEN &&
    this.thumbnailApi.srchMenshu === this.$$const.API.menshuAName.HONSHI
  ) {
    query.imageId = this.thumbnailApi.imageId
    query.issueDate = this.thumbnailApi.issueDate
    query.hakkoushaName = this.thumbnailApi.hakkoushaName
    query.srchMenshu = this.thumbnailApi.srchMenshu
    query.srchType = this.thumbnailApi.srchType
  } else if (
    query.srchKind === this.$$const.API.srchId.SHIMEN &&
    (this.thumbnailApi.srchMenshu === this.$$const.API.menshuAName.REGION ||
      this.thumbnailApi.srchMenshu === this.$$const.API.menshuAName.GAICHI)
  ) {
    query.imageId = this.thumbnailApi.imageId
    query.issueDate = this.thumbnailApi.issueDate
    query.countryArea = this.thumbnailApi.countryArea
    query.srchMenshu = this.thumbnailApi.srchMenshu
  } else if (query.srchKind === this.$$const.API.srchId.GRAPH) {
    query.imageId = this.thumbnailApi.imageId
    query.issueDate = this.thumbnailApi.issueDate
  }

  // APIをコールする
  const res: DangoResponse.thumbnailIF = await getThumbnail(query)

  // mstStatusの値をチェックする
  if (await checkMstStatus('thumbnail', res, 1)) {
    if (!res.result) {
      parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
      return
    }
    this.thumbnailApi.result = res.result
  }

  // ローディング画面を隠す
  this.$$hideLoading()

  // 警告メッセージがあれば表示する
  if (res.mstWarnMsg) {
    parent.$nuxt.$$alertOrException(res.mstWarnMsg)
  }
}

/**
 * 実データ情報を取得する
 * @return {Promise<void>}
 */
Vue.prototype.$$getRealData = async function (): Promise<void> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query = {
    domainNm: document.domain,
    srchKind: this.realDataApi.srchKind,
  } as DangoRequest.realDataIF

  // DB毎にパラメータを設定
  if (query.srchKind === this.$$const.API.srchId.KIJI) {
    query.imageId = this.realDataApi.imageId
    query.editFlg = this.realDataApi.editFlg
    query.retKindFlg = this.realDataApi.retKindFlg
    query.fhznflg = this.realDataApi.fhznflg
    query.itemId = this.realDataApi.itemId
  } else if (
    query.srchKind === this.$$const.API.srchId.SHIMEN &&
    this.realDataApi.srchMenshu === this.$$const.API.menshuAName.HONSHI
  ) {
    query.imageId = this.realDataApi.imageId
    query.miId = this.realDataApi.miId
    query.issueDate = this.realDataApi.issueDate
    query.editFlg = this.realDataApi.editFlg
    query.markFlg = this.realDataApi.markFlg
    query.srchKeyword = this.realDataApi.srchKeyword
    query.srchMenshu = this.realDataApi.srchMenshu
    query.srchType = this.realDataApi.srchType
    query.retKindFlg = this.realDataApi.retKindFlg
    query.fhznflg = this.realDataApi.fhznflg
  } else if (
    query.srchKind === this.$$const.API.srchId.SHIMEN &&
    (this.realDataApi.srchMenshu === this.$$const.API.menshuAName.REGION ||
      this.realDataApi.srchMenshu === this.$$const.API.menshuAName.GAICHI)
  ) {
    query.imageId = this.realDataApi.imageId
    query.issueDate = this.realDataApi.issueDate
    query.editFlg = this.realDataApi.editFlg
    query.markFlg = this.realDataApi.markFlg
    query.srchMenshu = this.realDataApi.srchMenshu
    query.retKindFlg = this.realDataApi.retKindFlg
    query.fhznflg = this.realDataApi.fhznflg
  } else if (query.srchKind === this.$$const.API.srchId.GRAPH) {
    query.imageId = this.realDataApi.imageId
    query.editFlg = this.realDataApi.editFlg
    query.retKindFlg = this.realDataApi.retKindFlg
    query.fhznflg = this.realDataApi.fhznflg
    query.itemId = this.realDataApi.itemId
  }

  if (this.realDataApi.editFlg === this.$$const.API.editFlg.PRTAREA_JPEG) {
    query.cropX = this.realDataApi.cropX
    query.cropY = this.realDataApi.cropY
    query.cropWidth = this.realDataApi.cropWidth
    query.cropHeight = this.realDataApi.cropHeight
  }

  // APIをコールする
  if (query.retKindFlg === this.$$const.API.retKindFlg.FORMAT_JSON) {
    const res: DangoResponse.realDataIF = await getRealData(query)

    // mstStatusの値をチェックする
    if (await checkMstStatus('realdata', res, 1)) {
      if (!res.result) {
        parent.$nuxt.$$alertOrException(
          parent.$nuxt.$$const.API_ERROR_MSG.COMMON
        )
        return
      }
      this.realDataApi.result = res.result
    }
  } else {
    const res: ArrayBuffer = await getRealDataBinary(query)
    this.realDataApi.binary = res
  }

  // ローディング画面を隠す
  this.$$hideLoading()
}

/**
 * 認証パスワードを変更する
 * @return {Promise<boolean>}
 */
Vue.prototype.$$changePassword = async function (): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query: DangoRequest.changePasswordIF = {
    domainNm: document.domain,
    newPwd: this.newPwd,
  }

  // APIをコールする
  const res: DangoResponse.changePasswordIF = await changePassword(query)

  // ローディング画面を隠す
  this.$$hideLoading()

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('changePassword', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  }

  return res.status
}

/**
 * マイフォルダ用保存キーを作成する
 * @return {Promise<string>}
 */
Vue.prototype.$$makeMyFolderHznKey = async function (): Promise<string> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query: DangoRequest.getmyfhznkeyIF = {
    domainNm: document.domain,
    creStatus: this.$$const.API.creStatus.MAKE_HZNKEY,
  }

  // APIをコールする
  const res: DangoResponse.getmyfhznkeyIF = await getmyfhznkey(query)
  console.log(res)

  // ローディング画面を隠す
  this.$$hideLoading()

  let hznkey = ''
  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('getMyFolderHznKey', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  } else if (!res.hznkey) {
    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
  } else {
    // 保存キーをセットする
    hznkey = res.hznkey
  }
  return hznkey
}

/**
 * マイフォルダを作成する
 * @params {string} hznkey
 * @return {Promise<boolean>}
 */
Vue.prototype.$$makeMyFolder = async function (
  hznkey: string
): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()
  // クエリを初期化
  const query: DangoRequest.getmyfhznkeyIF = {
    domainNm: document.domain,
    creStatus: this.$$const.API.creStatus.MAKE_MYFOLDER,
    hznkey,
  }

  // APIをコールする
  const res: DangoResponse.getmyfhznkeyIF = await getmyfhznkey(query)

  // ローディング画面を隠す
  this.$$hideLoading()

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('getMyFolderHznKey', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  } else if (res.loginInfo === undefined) {
    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
  } else {
    // ユーザー情報を保存する
    storeLoginInfo(res.loginInfo as DangoResponse.loginInfo)
  }

  return res.status
}

/**
 * マイフォルダを復元する
 * @params {string} hznkey
 * @return {Promise<boolean>}
 */
Vue.prototype.$$restoreMyFolder = async function (
  hznkey: string
): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()
  // クエリを初期化
  const query: DangoRequest.getmyfhznkeyIF = {
    domainNm: document.domain,
    creStatus: this.$$const.API.creStatus.RESTORE_MYFOLDER,
    hznkey,
  }

  // APIをコールする
  const res: DangoResponse.getmyfhznkeyIF = await getmyfhznkey(query)

  // ローディング画面を隠す
  this.$$hideLoading()

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('getMyFolderHznKey', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  } else if (res.loginInfo === undefined) {
    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
  } else {
    // ユーザー情報を保存する
    storeLoginInfo(res.loginInfo as DangoResponse.loginInfo)
  }

  return res.status
}

/**
 * マイフォルダを複製する
 * @return {Promise<string>}
 */
Vue.prototype.$$copyMyFolder = async function (): Promise<string> {
  // ローディング画面を表示する
  this.$$showLoading()
  // クエリを初期化
  const query: DangoRequest.getmyfhznkeyIF = {
    domainNm: document.domain,
    creStatus: this.$$const.API.creStatus.COPY_MYFOLDER,
    hznfolder: this.$$getLoginInfo('S_HZNFOLDER'),
  }

  // APIをコールする
  const res: DangoResponse.getmyfhznkeyIF = await getmyfhznkey(query)
  console.log(res)

  // ローディング画面を隠す
  this.$$hideLoading()

  let hznkey = ''
  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('getMyFolderHznKey', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  } else if (!res.hznkey) {
    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(parent.$nuxt.$$const.API_ERROR_MSG.COMMON)
  } else {
    // 保存キーをセットする
    hznkey = res.hznkey
  }

  return hznkey
}

/**
 * マイフォルダ保存情報を作成する
 * @params {string} kinouId
 * @params {string} hznJson
 * @return {Promise<boolean>}
 */
Vue.prototype.$$addMyFolderInfo = async function (
  kinouId: string,
  hznJson: string
): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()
  // クエリを初期化
  const query: DangoRequest.updmyfInfosIF = {
    domainNm: document.domain,
    hznfolder: this.$$getLoginInfo('S_HZNFOLDER'),
    kinouId,
    hznJson,
  }

  // APIをコールする
  const res: DangoResponse.updmyfInfosIF = await updmyfInfos(query)

  // ローディング画面を隠す
  this.$$hideLoading()

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('updateMyFolderInfo', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  }

  return res.status
}

/**
 * マイフォルダ保存情報を更新する
 * @params {string} kinouId
 * @params {string} meisaiId
 * @params {string} hznJson
 * @return {Promise<boolean>}
 */
Vue.prototype.$$updateMyFolderInfo = async function (
  kinouId: string,
  meisaiId: string,
  hznJson: string
): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query: DangoRequest.updmyfInfosIF = {
    domainNm: document.domain,
    hznfolder: this.$$getLoginInfo('S_HZNFOLDER'),
    kinouId,
    meisaiId,
    hznJson,
  }

  // APIをコールする
  const res: DangoResponse.updmyfInfosIF = await updmyfInfos(query)

  // ローディング画面を隠す
  this.$$hideLoading()

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('updateMyFolderInfo', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  }

  return res.status
}

/**
 * マイフォルダ保存情報を削除する
 * @params {string} kinouId
 * @params {string} meisaiId
 * @return {Promise<string>}
 */
Vue.prototype.$$deleteMyFolderInfo = async function (
  kinouId: string,
  meisaiId: string
): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query: DangoRequest.updmyfInfosIF = {
    domainNm: document.domain,
    hznfolder: this.$$getLoginInfo('S_HZNFOLDER'),
    kinouId,
    meisaiId,
  }

  // APIをコールする
  const res: DangoResponse.updmyfInfosIF = await updmyfInfos(query)

  // ローディング画面を隠す
  this.$$hideLoading()

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('updateMyFolderInfo', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  }

  return res.status
}

/**
 * マイフォルダ利用を解放する
 * @return {Promise<string>}
 */
Vue.prototype.$$releaseMyFolder = async function (): Promise<boolean> {
  // ローディング画面を表示する
  this.$$showLoading()

  // クエリを初期化
  const query: DangoRequest.myfusereleaseIF = {
    domainNm: document.domain,
    hznfolder: this.$$getLoginInfo('S_HZNFOLDER'),
  }

  // APIをコールする
  const res: DangoResponse.myfusereleaseIF = await myfuserelease(query)

  if (res.status === false) {
    // エラーコードをチェックする
    await checkDangoErrorCode('releaseMyFolder', res)

    // アラート表示 or 例外を投げる
    parent.$nuxt.$$alertOrException(res.errMsg!)
  } else {
    // ユーザー情報を保存する
    storeLoginInfo(res.loginInfo as DangoResponse.loginInfo)
  }

  // ローディング画面を隠す
  this.$$hideLoading()

  return res.status
}

/**
 * マイフォルダJSONを取得する
 * @params {keyof MyFolder.detailIF[]} key
 * @return {Promise<void>}
 */
Vue.prototype.$$getMyFolderJson = async function (
  keys: (keyof MyFolder.detailIF)[]
): Promise<void> {
  // ローディング画面を表示する
  this.$$showLoading()

  await Promise.all(
    keys.map(async (key: keyof MyFolder.detailIF) => {
      // APIをコールする
      const res:
        | MyFolder.quoteJsonIF
        | MyFolder.presearchJsonIF
        | MyFolder.favoriteKijiJsonIF
        | MyFolder.notePlusJsonIF = await getmyfdata(
        this.$config.MY_FOLDER_PATH[key].replace(
          '%s',
          this.$$getLoginInfo('S_HZNFOLDER')
        )
      )
      console.log(key, res)

      if (!res.credatetime) {
        // アラート表示 or 例外を投げる
        parent.$nuxt.$$alertOrException(
          parent.$nuxt.$$const.API_ERROR_MSG.COMMON
        )
      } else {
        // マイフォルダ情報を保存する
        storeMyFolderInfo(key, res)
      }
    })
  )

  // ローディング画面を隠す
  this.$$hideLoading()
}
